import React from 'react'
import {css} from '@emotion/react'

import PageWithHeader from '../layouts/PageWithHeader'

const containerCss = css`
  margin: 20px auto;
  width: 500px;
`

const NotFoundPage = (): JSX.Element => (
  <PageWithHeader pageConfig={{}}>
    <div css={containerCss}>
      <h1>Error - Page not found</h1>
      <p>This page doesn&#39;t exist.</p>
    </div>
  </PageWithHeader>
)

export default NotFoundPage
